import {
    ApiObjectTypeSchema,
    Claim,
    ClaimStatusSchema,
    ConnectedAccount,
    ServiceNameSchema
} from '@webapps/numeral-ui-core'
import { chain } from 'lodash'

export function isClaimServiceCreateInquiryAvailable(item?: Claim, connectedAccount?: ConnectedAccount): boolean {
    const hasPaymentOrderType = item?.related_payment_type === ApiObjectTypeSchema.enum.payment_order
    const hasSentStatus = item?.status === ClaimStatusSchema.enum.sent
    const hasInquiryCreationService = chain(connectedAccount?.services_activated)
        .includes(ServiceNameSchema.enum.inquiry_creation)
        .value()

    return hasPaymentOrderType && hasSentStatus && hasInquiryCreationService
}
